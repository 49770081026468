.biomarkerForm{
    label{
        font-weight: bold;
        padding-top: 12px;
    }
    .MuiSlider-root{
    .MuiSlider-markLabel{
        font-size: 12px;
        margin-top: -4px;
        transform: translateX(0);
        color: #000000;
        ~ .MuiSlider-markLabel{
            left: auto !important;
            right: 0;
        }
    }
}
}

// #heartRate > span.MuiSlider-markLabel
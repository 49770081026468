.stepper {
  position: relative;
  .Mui-disabled {
    background-color: #ddd !important;
  }
  &::before {
    content: '';
    width: 80%;
    border-bottom: 1px solid #ddd;
    position: absolute;
    top: 17px;
  }
  &::after {
    content: '';

    left: 10%;
    border-bottom: 1px solid #333333;
    position: absolute;
    top: 17px;
    transition: width 0.2s linear;
  }
  &.selected-0 {
    &::after {
      width: 20%;
    }
  }
  &.selected-1 {
    &::after {
      width: 40%;
    }
  }
  &.selected-2 {
    &::after {
      width: 60%;
    }
  }
  &.selected-3 {
    &::after {
      width: 80%;
    }
  }
  &.followUpSteps {
    &.selected-0 {
      &::after {
        width: 25%;
      }
    }
    &.selected-1 {
      &::after {
        width: 50%;
      }
    }
    &.selected-2 {
      &::after {
        width: 75%;
      }
    }
  }
}

.faq_main_container{
    width:100%;
    @media(max-width:475px){
        margin: 0px !important;

    }
}

.faq__container{
    overflow: hidden !important;
}

.wp-block-group{
    width: 70%;
    margin: auto;
        word-wrap: break-word;
        @media(max-width:475px){
            width: 90%;
        }
}

.is-resized{
    @media(max-width:930px) and (min-width:475px){
        width: 300px;
        overflow: scroll;
        height: auto;
    }

    @media(max-width:475px){
        width: 300px;
        margin: 10px 0px !important;
        overflow: scroll;
        height: auto;
    }
    img{
        object-fit: cover;
    }
}
.details__page{
&_profile{
    // margin-top: 20vh;
    background: #FFFFFF;
border-radius: 30px 30px 0px 0px;
}

&_logo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    width: 100%;
    background-color:  #F1F1F1;
  img{
    width: 198px;
    height: 80px;
  }
}
}
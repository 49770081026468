.footer {
  // position: fixed;
  bottom: 0;
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: transparent;
  div {
    font-family: 'Inter';
    font-size: 10px;
    border: none;
    font-weight: 500;
  }
  .footerLogo {
    max-width: 45px;
    vertical-align: middle;
  }
  &-link a {
    color: #333;
    text-decoration: underline;
  }
}

.footer-link{
  gap: 0.8rem;
}
$primary-color:#087E73;
$secondary-color:#E8F3F1;
$primary-text-color: #199A8E;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
body {
  font-family: 'Inter';
  margin: 0;
  letter-spacing: 0.01em;
  font-size: 16px;
  height: 100vh;
}
#root {
  min-height: 100%;
  height: 100%;
}
.wrapper-background {
  background: url('./../static/images/Patient_Portal_Full_Bkg.jpg') no-repeat
    100% 0;
  // background: url('./../static/images/Patient_Portal_Full_Bkg.png') no-repeat 0 0;
  background-size: cover;

  // @media only screen and (max-width: 600px) {
  //     background-image: url('./../static/images/Patient_Portal_Mobile_Bkg.png');
  // }
}

.Arrow--right {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #199a8e;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 0px 10px #199A8E;
  border-radius: 50px;
}

.app-screen {
  background-color: #ffffff;
  position: static;
  z-index: 1;
  max-width: 480px;
  margin: auto;
  height: 100%;
  min-height: 100vh;
}

.birth_date {
  > div {
    input {
      padding: 8px;
    }
  }
}

.static_disable {
  background-color: #d9d9d9 !important;
  pointer-events: none;
  color: black !important;
}

.static_button {
  all: unset;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  padding: 15px 10px;
  background: #199a8e;
  border-radius: 32px;
  color: #ffffff;
  width: 40%;
  display: flex;
  justify-content: center;
}
.stylebtn{
  position: absolute;
   top: 20px;
    right: 20px;
     border: none; 
     font-size: 20px; 
     font-Weight:800; 
    background-color: #fff;
}

.overflow-hidden-app {
  overflow-x: hidden;
}


@import './variables';
@import './bootstrap.display';
@import './bootstrap.align';
@import './utils';
@import './gradientbutton';
@import './helper';
@import './tables';
@import './invoice';
@import './footer';
@import './biomarker';
@import './stepper';
@import './login';
@import './header';
@import './detailsPage';
@import './appointment';
@import './thankuPage';
@import './Home';
@import './Ordertest.scss';
@import './PharmacySelection.scss';
$hover-color:#e8f5e9; 
$small-screen: 480px;
$medium-screen: 768px;
$large-screen: 1025px;
$primary-color: #199a8e;
$white-color: #ffffff;
$light-color: #f9fafb;
$gray-color: #898a8d;
$text-black: #101623;
$pending-color: #ff6868;
$error-color: #b00020;
$primary-font: "Inter", sans-serif;

.thankuPage {
  &__container {
    padding:3rem 2rem  1rem 2rem;
    font-family: 'Inter';
    font-style: normal;
    position: relative;
  }

  &__close{
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &__heading {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }

  &__image {
    img {
      width: 100%;
      height: 100%;
      margin: 1rem auto;
    }
  }

  &__text {
    font-size: 12px;
    width: 80%;
    margin: auto;
    font-weight: 500;
    text-align: center;
  }

  &__social_icon {
    display: flex;
    margin: 1rem auto;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    img:nth-child(1) {
      height: 60px;
      cursor: pointer;
    }
    img:nth-child(2) {
      height: 100px;
      // width: 100%;
      cursor: pointer;
    }
  }

  &__button{
    margin-top: 2rem;
    button{
      background-color: #FFF;
      width: 80%;
      margin: auto;
      border-radius: 8px;
      border: 2px solid $primary-color;
      padding: 0.4rem;
    }

    &__style{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        img{
            width: 90px;
            height: 40px;
        }

        span{
            display: block;
            color: #000;
            font-weight: 700;
            font-size: 14px;
        }
    }
  }
}

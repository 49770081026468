.loginContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    max-width: 100%;
  }

  &__option {
    margin-top: 10px;
    display: flex;
    padding: 14px 0;
    justify-content: center;
    background-color: $light-color;
    border: 1px solid $primary-color;
    border-radius: 24px;
    width: 100%;

    &__img {
      width: 24px;
      height: 24px;
    }
    &__select:focus {
      outline: none;
    }
    &__select {
      border: none;
      background-color: transparent;
      width: 80%;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: $primary-color;
      option {
        text-align: center;
      }
    }
  }

  &_login {
    box-shadow: none !important;
    min-height: 80vh;
    margin-top: 0;
    display: flex;
    justify-content: start;
    gap: 2rem;
    flex-direction: column;
    .slider-container {
      margin-top: 5vh;
    }
  }
  &_carousel {
    &_card {
      &__box {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: end;
        padding-bottom: 1rem;
        height: 100%;
        max-height: 50vh;
      }
      &__image {
        max-height: 316px;
        max-width: 316px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &__text {
        margin-top: 10px !important;
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        color: $primary-color;
      }
    }
  }
  &_button {
    border-radius: 32px !important;
    background-color: $primary-color !important;
    width: 320px;
    padding: 10px 0px !important;
  }

  &_number {
    margin-top: 10px;
    margin: auto;
    padding: 10px 0;
    background-color: $light-color;
    border: 1px solid $primary-color;
    border-radius: 24px;
  }

  &_number:focus {
    .loginContainer_icon {
      span {
        color: $primary-color;
      }
    }
  }

  &_icon {
    display: flex;
    align-items: center;
    > span {
      color: #a1a8b0;
    }
  }

  &_phone-input {
    padding-left: 10px !important;
    > div:before {
      transform: none !important;
      border: none !important;
    }
    > div:after {
      border: none !important;
    }
    > div {
      input {
        color: $primary-color !important;
      }
    }

    .Mui-focused {
      transform: none !important;
    }
  }

  &_checkbox {
    color: $primary-color !important;
    &_text {
      font-size: 13px !important;
      a {
        color: $primary-color;
      }
    }
  }

  &_country {
    display: flex;
    width: fit-content;
    margin-top: 2px;
    > div {
      color: $primary-color !important;
    }
    > div:before {
      border: none !important;
    }
  }

  &_otp {
    box-shadow: none !important;

    &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__input {
      display: flex;
      justify-content: center;
      width: 100% !important;
      // margin: auto !important;
      padding: 9px 0 !important;
      background-color: $light-color;
      border: 1px solid $primary-color !important;
      border-radius: 24px;
      padding-left: 10px !important;
      &__box {
        width: 82% !important;
        > div:before {
          transform: none !important;
          border: none !important;
        }
        > div:after {
          border: none !important;
        }
        > div {
          input {
            color: $primary-color !important;
          }
        }
        .Mui-focused {
          transform: none !important;
        }

        input {
          letter-spacing: 4px;
          text-align: center;
        }
      }
    }

    &_countdown {
      color: $white-color;
      margin-left: 5px;
    }

    &_button-container {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &_title {
      font-weight: 700 !important;
      font-size: 24px !important;
      line-height: 135% !important;
      color: $text-black;
    }

    &_text {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 150% !important;
      color: #a1a8b0;
      span {
        color: $text-black;
      }
    }
  }
}

.validation-error {
  color: $error-color !important;
  text-align: center !important;
}

.loginContainer_login .paging-item {
  svg {
    height: 10px;
    width: 10px;
  }
}

.loginContainer_login .active {
  svg {
    fill: $primary-color;
  }
}

.loginContainer_login {
  .slider-control-bottomcenter {
    position: relative;
    top: 30px;
  }
}

.login-text {
  font-family: 'Inter';
  display: flex;
  justify-content: center;
  margin: 20px 0 10px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #888888;
}

.icon-glow {
  color: $primary-color !important;
}

// .bodyBg {
//   background-color: $white-color;
// }

.profile_form {
  &__agreement {
    font-family: 'Inter';
    font-style: normal;
    padding-top: 0.8rem;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #50555c;
  }

  &__close {
    position: absolute;
    top: 0rem;
    right: 1rem;
    cursor: pointer;
  }

  &_profile {
    border-radius: 30px 30px 0px 0px;
    height: 80vh;
  }

  &__heading {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 150%;
    letter-spacing: 2.5px !important;
  }

  &_button {
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    margin-top: 1rem;
    font-size: 14px;
    padding: 1.2rem 0 !important;
    text-align: center;
  }

  &__input {
    display: flex;
    align-items: center;
    position: relative;
    margin: 1rem 0;
    gap: 0.6rem;
    width: 100%;
    border-radius: 50px;
    padding: 0.1rem 0;
    border: 1px solid rgba(0, 0, 0, 0.25);
    appearance: none;

    //   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    // input[type=date]:required:invalid::-webkit-datetime-edit {
    //     color: transparent;

    //   }

    //   input[type=date]:focus::-webkit-datetime-edit {
    //     color: black !important;
    // }

    //          input[type=date]:required:invalid::-webkit-datetime-edit {
    //           color: transparent;
    //         }

    //          input[type=date]:focus::-webkit-datetime-edit {
    //           color: black !important;
    //         }
    //          input[type=date]:required:invalid::-webkit-datetime-edit {
    //           color: transparent;
    // }

    // input[type=date]:focus::-webkit-datetime-edit {
    //   color: black !important;
    // }

    label {
      position: absolute;
      left: 4rem;
      color: #8e8e8e;
    }
    > input {
      padding: 0 !important;
      width: 80%;
      border: none !important;
      height: 40px;
      outline: none;
      font-family: 'Inter';
      background: transparent;
      font-style: normal;
      font-size: 16px;
      line-height: 100%;
    }

    img {
      height: 49px;
      width: 49px;
    }

    input[type='date'] {
      position: relative;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      // ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
      // ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
      // ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
      //   color: transparent;
      // }
    }

    input[type='date']:disabled::-webkit-datetime-edit,
    input[type='date']:focus::-webkit-datetime-edit,
    input[type='date']:required:invalid::-webkit-datetime-edit {
      // color: transparent !important;
      display: none;
    }

    input {
      &[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }

      &[type='date']:required:invalid::-webkit-datetime-edit {
        color: transparent;
        display: none !important;
      }
    }

    input[type='date']::-webkit-calendar-picker-indicator {
      background-position: right;
      background-size: auto;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 10px;
      width: auto;
    }
    input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

  }

  &__gender {
    margin: 1.4rem 0 1.4rem 0;
    &__input {
      display: flex;
      margin-top: 0.6rem;
      label {
        margin-right: 1.4rem;
        display: flex;
        gap: 0.4rem;
        align-items: center;
        input {
          display: none;
        }
        div {
          pointer-events: none;
        }
      }
    }
    &__label {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #101623;
    }
  }

  &__blood {
    margin: 1rem 0 0 0;
    > div {
      display: flex;
      justify-content: start;
      gap: 0.4rem;
    }

    input {
      position: absolute;
      // -webkit-appearance: none;
      height: inherit;
      width: inherit;
      opacity: 0;
    }

    &_circle {
      position: relative;
      z-index: 2;
      display: flex;
      margin: 1rem 0 0 0;
      align-items: center;
      justify-content: center;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #000000;
      // padding: 1rem;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      border: 0.5px solid #ff6868;

      &_selected {
        background-color: #ff6868;
        color: #fff;
      }
    }
  }

  &__readmore {
    display: flex;
    gap: 0.8rem;
    padding: 2rem 0 1rem 0;

    input {
      position: absolute;
      // -webkit-appearance: none;
      width: 15px;
      top: 0;
      left: 0;
      margin: 0;
      height: 15px;
      opacity: 0;
    }

    &__box {
      background: #d9d9d9;
      border: 0.5px solid #898a8d;
      border-radius: 3px;
      width: 20px;
      height: 15px;
      position: relative;
    }

    &__tick {
      &::after {
        content: '✔';
        color: #199a8e;
        display: block;
        position: absolute;
        top: -0.3rem;
        left: 2px;
      }
    }

    &__label {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 140.5%;
      color: #898a8d;
    }
  }
  
}

::placeholder {
  color: #d9d9d9;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d9d9d9;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d9d9d9;
}
.whtsp_switch{ 
  display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 0rem;
    margin: 1.6rem auto;
    // margin-top: 124px;
    .label_with_icon{
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
    }
  svg{
      margin-top: -7px;
      padding-left: 4px;
      margin-bottom: -6px;
      color: green;
    }
    .label_text{
      font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 15px;
          color: #199a8e !important;
    }
  }
  

// .profile_form__input{
//   float: right;
//   width: 50%;s
// }
// .profile_form__input {
//   display: flex;
//   align-items: center;
//   position: relative;
//   margin: 1rem 0;
//   gap: 0.6rem;
//   width: 100%;
//   border-radius: 50px;
//   padding: 0.1rem 0;
//   border: 1px solid rgba(0, 0, 0, 0.25);
// }

// .profile_form__input label {
//   position: absolute;
//   left: 4rem;
//   color: #8e8e8e;
// }

// .profile_form__input > input {
//   padding: 0 !important;
//   width: 80%;
//   border: none !important;
//   height: 40px;
//   outline: none;
//   font-family: 'Inter';
//   background: transparent;
//   font-style: normal;
//   font-size: 16px;
//   line-height: 100%;
// }

// .profile_form__input img {
//   margin: 10px;
//   padding: 5px;
//   height: 5vh;
//   width: 3vw;
// }

// .profile_form__input input[type='number']::-webkit-inner-spin-button,
// .profile_form__input input[type='number']::-webkit-outer-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }
// .profile_form__input img {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   height: 25px;
//   width: 24px;
//   padding-left: 10px;
// }
.other_sec {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-right: 220px;
}
// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     margin: 0;
// }

.profileForm {
  &__container {
    position: absolute;
    bottom: 0;
    left: 50%;
    max-width: $medium-screen;
    background: #ffffff !important;
  }
  .header-line{
    justify-content: space-between;
    flex-direction: row;
  }
}
.header-main {
  display: flex;
  align-items: center;
  // padding: 10px;
  gap: 0px;
  border: 1px solid #ffffff;
  .btn-1{
    margin: auto;
    border: none;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 48px;
    color: blue;
    cursor: pointer;
  }
  figure{
    margin: 0;
    flex-shrink: 0;
    max-width: 80px;

    img{
      max-width: 80%;
    }
  
  }
  h2{
    margin-bottom: 0;
    font-size: 14px;
  }
  p{
    margin-top: 0;
    margin-bottom: 0;
  }
  button{
    margin: auto;
    border: none;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    img{
      width: 47px;
      margin-bottom: 1px;
    }
    
  }
}

    
    .headerContainer {
      position: sticky;
    }
    
    .headerContainer__box {
      display: flex;
      position: relative;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;
      width: 90%;
      margin: 0.3rem auto;
    }
    
    .headerContainer__dropDown {
      position: absolute;
      top: 2.4rem;
      background-color: #FFF;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      padding: 0 1rem;
    }
    
    .headerContainer__dropDown ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      align-items: center;
      width: 100%;
    }
    
    .headerContainer__dropDown ul li {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      width: inherit;
      font-size: 12px;
      line-height: 19px;
      color: #101623;
      padding: 0.4rem 0;
    }


.pathology-selection {
  height: calc(100vh - 130px);
  h2{
    border: none;
    display: flex;
    align-items: center;
    color: #2096b3;
      font-size: large;
      .back-button {
      background: none;
      border: none;
      font-size: 24px; 
  cursor: pointer;
    }
  }
  .msg{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
    font-weight: 700;
    color: #2096b3;
  }
}

.connect-new-card {
    padding: 20px 15px;
    
  
    button {
      display: block;
      position: relative;
      width: 100%;
      padding: 8px; 
      max-height: 50%;
      border-radius: 10px;
      margin-top: 15px;
      color: rgb(12, 185, 128);
      box-shadow: 0 2px 10px #888;

      .heading-col-pathology{
        display: flex;
        align-items: center;
        gap: 10px;
  
        
      }

      .pathology-img {
        flex-shrink: 0;
        figure {
          margin: 0;

          img {
            max-width: 60px; 
          }
        }
      }
  
      
  
      .time {
        span {
          display: inline-block;
          &:first-child {
            margin-right: 10px;
          }
        }
        svg {
          width: 18px;
          vertical-align: middle;
        }
      }
  
      .pathology-title {
        margin-left: 6px; 
        text-align: left;
        flex: 1 1 100%;

        h2 {
          font-size: 16px;
          margin: 0;
        }

        a {
          display: block;
        }
      }
      .pathology-address {
        flex-shrink: 0;
        max-width: 130px;
        text-align: left;
        padding-left: 20px;
        position: relative;
        color: #6e6e6e;
        svg {
          position: absolute;
          left: 0;
          top: 24px;
          width: 20px;
          height: 22px;
        }
        .phn{
          svg{
            position: absolute;
            left: 0;
            top: 0px;
            width: 20px;
            height: 22px;
          }
        }
      }
    }
    
   
    
    
  }
  
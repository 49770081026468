
.home {
   display: flex;
   flex-direction: column; 
   &.min-height-100vh {
    min-height: 100vh;
  }
  }
  
  .home_container {
    // background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; 
    }
  
  
  .home_sub_container {
    display: block;
  }
  
  .address_num {
  
    .header-main {
      display: flex;
      align-items: center;
      padding: 10px;
      gap: 0px;
      border: 1px solid #ffffff;
      
      figure{
        margin: 0;
        flex-shrink: 0;
        max-width: 80px;

        img{
          max-width: 80%;
        }
      
      }
      h2{
        margin-bottom: 0;
        font-size: 14px;
      }
      p{
        margin-top: 0;
        margin-bottom: 0;
      }
      button{
        margin: auto;
        border: none;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-left: 70px;

        img{
          width: 47px;
          margin-bottom: 1px;
        }
        
      }
    }
    span {
      margin: 0;
      font-size: 9px;
      color: #10828e;
    }
    
    .headerContainer {
      position: sticky;
    }
    
    .headerContainer__box {
      display: flex;
      position: relative;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;
      width: 90%;
      margin: 0.3rem auto;
    }
    
    .headerContainer__dropDown {
      position: absolute;
      top: 3.4rem;
      background-color: #FFF;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      padding: 0 1rem;
    }
    
    .headerContainer__dropDown ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      align-items: center;
      width: 100%;
    }
    
    .headerContainer__dropDown ul li {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      width: inherit;
      font-size: 12px;
      line-height: 19px;
      color: #101623;
      padding: 0.4rem 0;
    }
    
  }
  
  .address_num img {
    max-width: 100px; 
    max-height: 100px; 
    margin: 10px 0; 
  }
  .emergency-service-bar{
    box-shadow: 0 2px 10px #888;
    width: 100%;
    padding: 10px 15px;
    overflow: hidden; 
    position: relative;
    background-color: #95e4e8;
  }
  .ticker-texts {
    font-size: 20px;
    white-space: nowrap;
    animation: moveLeftToRight 10s linear infinite;
  }
  
  @keyframes moveLeftToRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .welcome-card{
    padding: 20px 15px;
    box-sizing: border-box;
    margin: 0px;
    .welcome-crd{  
     
    h4{
      font-size: 20px;
      text-align: center;
      margin-bottom: 15px;
      font-weight: 550;
      color: #10828e;
    }
    p{
      text-align: center;
      margin-top: 0;
      color: #018A91;

    }
    .message-container {
      position: relative;
    }
    
    .read-more-button {
      // position: absolute;
      // bottom: 0;
      // right: 0;
      // position: absolute;
      // bottom: 0;
      // right: 0;
      border: none;
      // padding-right: 80px;
      // background: none;
    }
    
  }
    
    .image-slider {
      display: flex;
      overflow: hidden;
      animation: moveLeftToRight 10s linear infinite;
    }
    
    .slide {
      flex-shrink: 0;
      width: 100%;
      max-width: 30%;
      margin-right: 10px;
    }
    
    .slide img {
      // width: 100%;
      // margin: auto;
      // max-height: 76%;
      // border-radius: 5px;
    height: 120px;
    max-width: 100px;
    max-height: 120px;
    margin: auto;
    border-radius: 5px;
    }

   .item{
    .customImg img{
      width: 300px!important;
      height: 300px!important;
      max-width: 120px!important;
      max-height: 120px!important;
  }
   }
    
    @keyframes moveLeftToRight {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
    
    span {
      // display: block; 
      // margin: 0 auto; 
      // text-align: center; 
      // display: block; 
      // margin: 0 auto; 
      // text-align: center; 
      font-size: 20px;
      font-weight: 550;
      // padding: 10px; 
      color: #10828e;
    }
    .title{
      font-size: 20px;
      font-weight: 550;
      color: #10828e;
      text-align: center;

    }

  }

  .connect-with-card {
    padding: 20px 15px;
    margin-top: -24px;
    button {
      display: block;
    position: relative;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    margin-top: 15px;
    color: #10828e;
    // box-shadow: 0 2px 10px #888;
    box-shadow: 0px 4px 8px #888;

      img {
        position: absolute;
        left: 10px;
        left: 10px;
        top: 8px;
        max-width: 44px;
      }
    }
    button:hover {
      border: 1px solid #199a8e;
      box-shadow: 0px 0px 12px #199a8e;
    }
  }
  .social-links{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
  }
  
  
.btn-success{background: linear-gradient(
-135deg,#1de9b6 0%,#049dbf 100%);
text-shadow: #6c6c6c -1px 0px 2px;
color: #ffffff !important;
}

.btn-seccondary{    
    background: linear-gradient(-135deg,#899FD4 0%,#A389D4 100%);
    text-shadow: #6c6c6c -1px 0px 2px;
    color: #ffffff;
}